import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Document from 'components/document'

export default () => (
  <div>
    <StaticQuery
      query={graphql`
      query ShareholdersQuery {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: [DESC] }
          filter: {
            frontmatter: {
              shareholders: {
                eq: true
              }
            }
          }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                date
                title
                file
                featured
                shareholders
              }
            }
          }
        }
      }
    `}
      render={data => (
        <div>
          <h4 className="h4">Related documents</h4>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Document
              key={node.id}
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              file={node.frontmatter.file}
            />
          ))}
        </div>
      )}
    />
  </div>
)