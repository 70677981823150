import React from "react"
import { Helmet } from "react-helmet"
import Layout from "layouts/layout"
import ColumnLayout from "layouts/column-layout"
import RelatedDocuments from "pages/investors/shareholders/related-documents"
import ListOfShareholders from "./table/_list-of-shareholders"
import DirectorsShareholdings from "./table/_directors-shareholdings"

export default () => (
  <Layout>
    <Helmet>
      <title>Shareholders | Helios Underwriting</title>
    </Helmet>
    <ColumnLayout aside={<RelatedDocuments />}>
      <div className="o-container">
        <h1 className="h1 mb-40">Shareholders</h1>
        <p className="lead mb-40 u-color-emperor">
          Helios Underwriting Plc has 78,110,302 ordinary shares of £0.10 in
          issue, including 5,667,335 ordinary shares which are held in treasury.
          Consequently, the total number of shares in issue as of 15 November
          2024 with voting rights is 72,442,947 ordinary shares of £0.10 each.
          Each ordinary share carries the right to vote and there are no
          restrictions on transfer 49.79% of shares in issue are not in public
          hands.
        </p>

        <ListOfShareholders />
        <DirectorsShareholdings />

        <div className="my-40 u-color-emperor">
          <p className="u-text-small">
            * Tom Libassi does not hold ordinary shares in Helios Underwriting
            personally ("Shares") but is the Co-founder and Managing Partner of
            Resolute Global Partners Limited, which funds under management of,
            or associated with, holds 13,413,500 Shares.
          </p>
          <p className="u-text-small">
            ** 622,500 of Nigel Hanbury’s shares are jointly owned in accordance
            with the Company’s Joint Share Ownership Plan - 300,000 of which are
            detailed in the announcement made by the Company on 14 December 2017
            and 322,500 of which are detailed in the announcement made by the
            Company on 17 August 2021.
          </p>
          <p className="u-text-small">
            *** 477,500 of Arthur Manner’s shares are jointly owned in
            accordance with the Company’s Joint Share Ownership Plan 200,000 of
            which are detailed in the announcement made by the Company on 14
            December 2017 and 277,500 of which are detailed in the announcement
            made by the Company on 17 August 2021.
          </p>
        </div>
      </div>
    </ColumnLayout>
  </Layout>
)
