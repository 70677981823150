import React from "react"

export default () => (
  <div className="o-table__container my-40">
    <h2 className="h2">List of shareholders</h2>

    <p className="o-table__title">
      <span className="u-bold">
        Holding in excess of 3% of issued share capital excluding treasury
        shares
      </span>{" "}
      (as at 15 November 2024)
    </p>

    <table className="o-table o-table--llr">
      <thead>
        <tr>
          <th>Shareholders</th>
          <th>Shareholding</th>
          <th>Percentage of issued share capital</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Resolute Global Partners Ltd</td>
          <td>13,413,500*</td>
          <td>18.52%</td>
        </tr>
        <tr>
          <td>Polar Capital Funds PLC</td>
          <td>10,875,000</td>
          <td>15.01%</td>
        </tr>
        <tr>
          <td>N J Hanbury (either personally or has an interest in)</td>
          <td>9,494,725**</td>
          <td>13.11%</td>
        </tr>
        <tr>
          <td>IPGL Ltd</td>
          <td>5,735,635</td>
          <td>7.92%</td>
        </tr>
        <tr>
          <td>Will Roseff</td>
          <td>5,587,695</td>
          <td>7.71%</td>
        </tr>
        <tr>
          <td>Arbuthnot Latham (Nominees) Limited</td>
          <td>4,290,651</td>
          <td>5.92%</td>
        </tr>
        <tr>
          <td>Ardnave Capital Ltd</td>
          <td>3,063,669</td>
          <td>4.23%</td>
        </tr>
      </tbody>
    </table>
  </div>
)
